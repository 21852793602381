import React, { useEffect, useState, useContext } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styles from './BookingFormMobile.module.css';
import useWindowSize from "utils/useWindowSize";
import moment from 'moment-timezone';
import CalendarDataContext from '../../../contexts/CalendarDataContext';
import axios from 'axios';
import { extendMoment } from 'moment-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCircleChevronLeft, faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import ButtonBootstraps from 'react-bootstrap/Button';
const momentRange = extendMoment(moment);


const BookingForm = ({ selectedDates, onDateChange, maxOccupancy, maxPets, currencySymbol, pageId, disableAvailability, hideOnlineBooking, setShowEnquire, minPrice, maxPrice, bookingCurrency, handleChangeGuests, bookingDictinaries }) => {
    const CalendarData = useContext(CalendarDataContext);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const { startDate, endDate } = selectedDates;
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [pets, setPets] = useState(0);
    const [bookingData, setBookingData] = useState(null);
    const windowSize = useWindowSize();
    const [calendarCols, setCalendarCols] = useState(2);
    const [error, setError] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [orientation, setOrientation] = useState('horizonatal')
    const [withFullScreenPortal, setWithFullScreenPortal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [focused, setFocused] = useState(true);

    const [calendarIsEditable, setCalendarIsEditable] = useState(false);
    const [guestIsEditable, setGuestIsEditable] = useState(false);

    useEffect(() => {
        handleChangeGuests(adults, children, infants, pets);
    }, [adults, children, infants, pets]);


    useEffect(() => {
        if (windowSize.width > 800) {
            setCalendarCols(2);
            setOrientation('horizontal');
            setWithFullScreenPortal(false);
            setIsMobile(false);
            document.body.style.overflow = 'visible';
        }

        if (windowSize.width < 800) {
            setCalendarCols(4);
            setOrientation('vertical');
            setWithFullScreenPortal(true);
            setIsMobile(true);
        }
    }, [windowSize.width]);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'visible';
        if (isOpen) {
           window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [isOpen]); 

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentUrl.search);

        const adults = searchParams.get("Adults");
        const children = searchParams.get("Children");
        const infants = searchParams.get("Infants");
        const checkIn = searchParams.get("CheckIn");
        const checkOut = searchParams.get("CheckOut");

        setAdults(adults || 1);
        setChildren(children || 0);
        setInfants(infants || 0);
        // Convert to moment.js objects if the parameters are present
        if (checkIn && checkOut) {
            onDateChange(moment.utc(checkIn, "DD/MM/YYYY"), moment.utc(checkOut, "DD/MM/YYYY"));
        }
    }, []); 

    useEffect(() => {
        const fetchBookingData = async () => {
            // Don't fetch booking data until dates and counts are selected
            if (startDate === null || endDate === null) {
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ipro/webmethod.ashx`, {
                    params: {
                        methodName: 'ipro.acc.booking.calc',
                        checkin: startDate.format('DD/MM/YYYY'),
                        checkout: endDate.format('DD/MM/YYYY'),
                        adults,
                        children,
                        infants,
                        pets,
                        propertyid: pageId,
                        propertylanguageId: 0,
                        sourceId: '',
                        currency: currencySymbol
                    }
                });

                if (response.data.Error) {
                    setError(response.data.Error);
                } else {
                    setBookingData(response.data);
                    setError(null); // clear any previous error
                }
            } catch (error) {
                console.error("Failed to fetch booking data:", error);
            }
        };

        fetchBookingData();
    }, [startDate, endDate, adults, children, infants, pets, currencySymbol])

    const isBlocked = day => {
        const dayInUTC = day.utc();
        const startDateInUTC = startDate ? startDate.utc() : null;

        const dateString = dayInUTC.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));
        const startDateString = startDateInUTC ? startDateInUTC.format('DD/MM/YYYY') : null;
        const startDateObject = startDateString ? CalendarData.find(({ t }) => t.startsWith(startDateString)) : null;

        let selectedBookedDateInRange = hasSelectedBookedDateInRange();

        if (currentDateObject && !currentDateObject.e) {
            return true;
        }

        if (startDateObject) {
            if (hasBookedDateInRange(startDateInUTC, dayInUTC) && startDateInUTC && !endDate) {
                return true;
            }
        }

        // Blocking all dates before today
        if (dayInUTC.isBefore(moment.utc(), 'day')) {
            return true;
        }

        // Blocking all dates before the selected start date
        if (startDateInUTC && dayInUTC.isBefore(startDateInUTC, 'day') && !endDate) {
            return true;
        }

        if (startDateInUTC && endDate) {
            return !(currentDateObject?.cs || currentDateObject?.sbs);
        }

        // If a start date is selected, use the rules for the selected date
        if (startDateInUTC && startDateObject) {
            // startDate is the same as the current day
            if (startDateInUTC.isSame(dayInUTC, 'day')) {
                return false;
            }

            // startDate is a 'cs', so we look for 'ce' after this date
            if (startDateObject.cs && dayInUTC.isAfter(startDateInUTC, 'day')) {
                if (currentDateObject && currentDateObject.ce && !hasBookedDateInRange(startDateInUTC, dayInUTC)) {
                    const nightDifference = dayInUTC.diff(startDateInUTC, 'days') - 1;

                    if (isWithinMinStay(startDateObject.ms, nightDifference)) {
                        return false; // date is 'ce' and no booked dates in between
                    }
                }
            }
            // startDate is a 'sbs', so we look for 'sbe' within 6 nights after this date
            if (startDateObject.sbs && dayInUTC.isAfter(startDateInUTC, 'day') && dayInUTC.diff(startDateInUTC, 'days') < 7) {
                if (currentDateObject && currentDateObject.sbe && !hasBookedDateInRange(startDateInUTC, dayInUTC)) {
                    const nightDifference = dayInUTC.diff(startDateInUTC, 'days') - 1;
                    if (isWithinMinStay(startDateObject.ms, nightDifference)) {
                        return false; // date is 'ce' and no booked dates in between
                    }
                }
            }
            return true;
        }

        // Default behavior when no start date is selected
        if (!currentDateObject || !currentDateObject.e) {
            return true;
        }
        return !(currentDateObject.cs || currentDateObject.sbs);
    };

    const hasBookedDateInRange = (startDate, endDates) => {
        if (!startDate || !endDates) {
            // If either date is null, we cannot create a range
            return false;
        }

        // Ensure endDates is always an array
        if (!Array.isArray(endDates)) {
            endDates = [endDates];
        }

        // We only check against the first endDate
        const endDate = endDates[0];
        const range = startDate.isBefore(endDate, 'day') ? momentRange.range(startDate, endDate) : momentRange.range(endDate, startDate);
        return Array.from(range.by('days')).some(dateInRange => {
            const dateInRangeString = dateInRange.format('DD/MM/YYYY');
            const dateInRangeObject = CalendarData.find(({ t }) => t.startsWith(dateInRangeString));
            return dateInRangeObject ? !dateInRangeObject.e : false;
        });
    };

    const isWithinMinStay = (minStay, nightDifference) => {
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return false;
        }

        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return nightDifference + 1 >= minStayNumber; // Include the start date
            } else {
                const availableStays = minStay.split(',').map(Number);
                return availableStays.includes(nightDifference + 1); // Include the start date
            }
        }

        else if (typeof minStay === 'number') { // Check if it's a single number

            return nightDifference + 1 === minStay; // Include the start date
        }
        console.error('Invalid minStay value: ', minStay);
        return true;
    };

    const addMinStayToStartDate = (startDate, minStay) => {

        const date = moment(startDate);
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return null;
        }
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return date.clone().add(minStayNumber, 'days');
            } else if (minStay.includes(',')) {
                const minStayNumbers = minStay.split(',').map(Number);
                return minStayNumbers.map(num => date.clone().add(num, 'days'));
            }
        } else if (typeof minStay === 'number') {
            return date.clone().add(minStay, 'days');
        }

        console.error('Invalid minStay value: ', minStay);
        return null;
    };

    // Add this new function to get the minStay for a given date
    const getMinStayForDate = (date) => {
        const dateString = date ? date.format('DD/MM/YYYY') : null;
        const dateObject = dateString ? CalendarData.find(({ t }) => t.startsWith(dateString)) : null;

        // Add a check for undefined or null values
        return dateObject && dateObject.ms ? dateObject.ms : '1+';
    };


    const hasSelectedBookedDateInRange = (day) => {
        let date = startDate || day;
        let minStay = getMinStayForDate(date);

        let potentialEndDate = addMinStayToStartDate(date, minStay);

        const satisfiesCondition = !hasBookedDateInRange(date, potentialEndDate);
        return !satisfiesCondition;
    }


    const renderDayContents = day => {

        let classNames = '';

        const dateString = day.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));

        const startDateString = startDate ? startDate.format('DD/MM/YYYY') : null;
        const startDateObject = startDateString ? CalendarData.find(({ t }) => t.startsWith(startDateString)) : null;

        let tooltipText = '';

        const isSelectedDate = dateString === startDateString;
        const selectedBookedDateInRange = hasSelectedBookedDateInRange();


        if (currentDateObject) {

            if (selectedBookedDateInRange) {
                classNames += ' no-hover';
            }
            const newStartDate = moment(day);
            const potentialEndDate = addMinStayToStartDate(newStartDate, currentDateObject.ms);

            const satisfiesCondition = !hasBookedDateInRange(newStartDate, potentialEndDate);

            if (typeof currentDateObject.ms === 'string' && currentDateObject.e) {
                const minStayNumber = parseInt(currentDateObject.ms.replace('+', ''), 10);

                 tooltipText = (!satisfiesCondition && (isSelectedDate || !startDate)) ? `${minStayNumber} nights minimum` : "";
                if (!satisfiesCondition && (isSelectedDate || !startDate)) {
                    setShowTooltip(true);
                }
            } else {
                if (currentDateObject.e) {
                    tooltipText = satisfiesCondition ? "" : "Nights minimum";
                }

            }

            if (!currentDateObject.cs && !currentDateObject.sbs) {
                tooltipText = 'Not a changeover day';
            }
            if (!currentDateObject.e) {
                tooltipText = 'Booked';
                classNames += ' booked tw-bg-gray-300 tw-text-white tw-cursor-not-allowed';
            }
            if (currentDateObject.e && currentDateObject.cs || currentDateObject.sbs && satisfiesCondition) {
                //   tooltipText = 'Avaliable as a start day';
            }
        }

        if (currentDateObject?.sbs && !currentDateObject?.cs && startDate && !endDate && day.diff(startDate, 'days') === 7) {
            classNames += 'disabledDay ';
        }



        return (
            <div className={`${classNames} tw-relative tw-relative tw-flex tw-h-full tw-justify-center tw-items-center`}
            // onMouseMove={handleMouseMove}
            // onMouseLeave={handleMouseLeave}
            //data-tip={tooltipText}
            >
                {day.format('D')}
                {(showTooltip && ((startDate && endDate && tooltipText.length > 0) || (!startDate && !endDate) && tooltipText && tooltipText.length > 0)) &&
                    <div className='calendar_tooltipText'
                        style={{
                            position: 'absolute',
                            left: `${cursorPos.x - 60}px`,
                            top: `${cursorPos.y + 25}px`
                        }}
                    >
                        {tooltipText}
                    </div>
                }
            </div>
        );
    };

    const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {

        if (startDate && endDate) {
            onDateChange(newStartDate, null);
            setFocusedInput('endDate');
            return;
        }


        if (disableAvailability) {
            onDateChange(newStartDate, newEndDate);
            setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
            return;
        }

        let selectedBookedDateInRange = hasSelectedBookedDateInRange(newStartDate || newEndDate);
        if (selectedBookedDateInRange) {
            onDateChange(null, null);
            setFocusedInput('startDate');
            return;
        }


        if (startDate && newStartDate && endDate && newEndDate) {
            if (newStartDate.isAfter(startDate) && newStartDate.isBefore(endDate)) {
                onDateChange(newStartDate, null);
                setFocusedInput('startDate');
                return;
            }
            if (newStartDate.isBefore(startDate)) {
                onDateChange(newStartDate, null);
                setFocusedInput('startDate');
                return;
            }
            if (newEndDate.isAfter(endDate)) {
                onDateChange(newEndDate, null);
                setFocusedInput('startDate');
                return;
            }
        }


        onDateChange(newStartDate, newEndDate);
        setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
    };

    const handleClear = () => {
        onDateChange(null);

    };


    const handleClose = () => {
        setFocusedInput(false);
    };

    const renderCalendarInfo = () => (
        <div className="tw-flex   tw-mb-2">
            <div className="tw-flex tw-items-center">
                <div className="tw-w-4 tw-h-4 tw-mr-2 tw-bg-primary"></div>
                <span>Selected</span>
            </div>

            <div className="tw-flex tw-items-center tw-ml-4">
                <div className="tw-w-4 tw-h-4 tw-mr-2 tw-bg-gray-300"></div>
                <span>Booked</span>
            </div>

        </div>
    );

    const renderDatesInfo = () => (
        <div className="tw-flex tw-justify-between tw-px-4 tw-py-2 tw-border-b">
            <div className="">
                <b>{getDuration()}</b>
            </div>
            <div onClick={() => setCalendarIsEditable(true)} className='tw-font-bold'>
                Edit
            </div>
        </div>
    );

    const renderGuestInfo = () => (
        <div className="tw-flex tw-justify-between tw-px-4 tw-py-2 tw-font-bold tw-border-b">
            <div className="">
                <div className="tw-flex tw-items-center">
                    Guests: {parseInt(adults) + parseInt(children) + parseInt(infants)}
                </div>
                <div className="tw-flex tw-items-center">
                    {pets > 0 && <div className="tw-flex tw-items-center tw-mr-2">Pets: {pets}</div>}
                </div>
            </div>
            <div onClick={() => setGuestIsEditable(true)} className=''>
                Edit
            </div>
        </div>
    );

    const getDuration = () => {
        if (startDate && endDate) {
            const duration = endDate.diff(startDate, 'days');
            const formattedStartDate = startDate.format('MMM D, YYYY');
            const formattedEndDate = endDate.format('MMM D, YYYY');
            return `${formattedStartDate} - ${formattedEndDate} (${duration} nights)`;
        }
        return 'Dates are not selected';
    };


    const NavButton = ({ text, style }) => {
        return (
            <button style={{ ...style }}>
                {text}
            </button>
        );
    };

    const prevButtonStyle = {
        border: '1px solid #000',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '14px',
        dipslay: 'block',
        marginTop: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        width: 'calc(100% - 20px)'
    };

    const nextButtonStyle = {
        border: '1px solid #000',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '14px',
        dipslay: 'block',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        width: 'calc(100% - 20px)'
    };


    const getDurationBooking = () => {
        if (startDate && endDate) {
            const duration = endDate.diff(startDate, 'days');
            const formattedStartDate = startDate.format('Do  MMM YYYY');
            const formattedEndDate = endDate.format('Do  MMM YYYY');
            return (
                <div className='tw-flex '>
                    <FontAwesomeIcon
                        icon={faCalendarDays}
                        className="tw-mr-2 tw-mt-1"
                        style={{
                            color: '#a3a3a3',
                            fontSize: '1.5rem'
                        }}
                    />
                    <div>
                        <b>{formattedStartDate} - {formattedEndDate}</b><br />
                        <span className="">{duration} {duration > 1 ? 'nights' : 'night'}</span>
                    </div>
                </div>
            )
        }
        return '';
    };

    return (
        <>
            <div className="tw-fixed tw-z-[999999] tw-bottom-0 tw-bg-white tw-w-full tw-p-3 tw-shadow-lg">
                {bookingData && bookingData.RenterTotal > 0 ? (
                    <div className="tw-text-sm tw-font-semibold">
                        <div className='tw-mb-2 tw-leading-none'>
                            {hideOnlineBooking ? (
                                <>
                                    {moment(startDate).format('D[th] MMM YYYY')} to {moment(endDate).format('D[th] MMM YYYY')}
                                </>
                            ) : (
                                <>
                                    Book your stay for (
                                    {moment(startDate).format('D[th] MMM YYYY')} to {moment(endDate).format('D[th] MMM YYYY')}
                                    )
                                </>
                            )}
                            <br />
                            <span className="tw-text-lg tw-font-bold">
                                Total: {bookingData.CurrencySymbol}{bookingData.RenterTotal}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="tw-flex-grow"></div>
                )}

                {!bookingData && minPrice !== null && maxPrice !== null && minPrice > 0 && maxPrice > 0 && (
                    <div className="tw-text-md tw-font-medium tw-mb-2">
                        Price Range: {bookingCurrency}{minPrice} - {bookingCurrency}{maxPrice}
                    </div>
                )}

                <div className="tw-flex-grow tw-flex tw-justify-between tw-gap-3">
                    {!hideOnlineBooking && hideOnlineBooking !== null ? (
                        <>
                           
                            <button
                                className="tw-w-full btn btn-primary !tw-px-10 tw-py-1 !tw-text-white !tw-font-bold"
                                onClick={() => setShowEnquire(true)}
                            >
                                Enquire
                            </button>
                            <button
                                className="tw-w-full btn btn-dark !tw-px-10 tw-py-1 !tw-text-white !tw-font-bold"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Reserve
                            </button>
                        </>
                    ) : (
                        <button
                            className="tw-w-full btn btn-primary !tw-px-10 tw-py-1 !tw-text-white !tw-font-bold"
                            onClick={() => setShowEnquire(true)}
                        >
                            Enquire
                        </button>
                    )}
                </div>
            </div>

            {isOpen && 
                <div className="tw-fixed tw-z-[999999] tw-top-0 tw-bg-white tw-w-full tw-overflow-scroll tw-h-full">

                    <div
                        className={`${styles.Back}`}

                    >
                        <FontAwesomeIcon icon={faCircleChevronLeft} onClick={() => setIsOpen(!isOpen)} />
                    </div>

                    {renderDatesInfo()}

                    {renderGuestInfo()}

                    <div className='tw-p-5'>
                        {calendarIsEditable && (
                            <div className={`tw-fixed tw-h-full tw-w-full tw-bg-white tw-z-50 tw-top-0 tw-left-0 ${calendarIsEditable ? styles.slideInRightAnimation : ''}`} >
                                <div
                                    className={`${styles.Save}`}

                                >
                                    <ButtonBootstraps className='!tw-text-white' variant="primary" type="submit" onClick={() => setCalendarIsEditable(false)}>
                                        Save
                                    </ButtonBootstraps>




                                </div>

                              

                                <div className='tw-p-3'>
  
                                    <div className="date-picker-container date-picker-container-mobile " style={{ height: '50vh' }}>
                                        <DayPickerRangeController
                                            startDate={startDate}
                                            endDate={endDate}
                                            onDatesChange={handleDatesChange}
                                            focusedInput={focusedInput}
                                            isOutsideRange={isBlocked}
                                            verticalHeight={450}
                                            renderDayContents={renderDayContents}
                                            navPrev={<NavButton text="Load more dates" style={prevButtonStyle} />}
                                            navNext={<NavButton text="Load more dates" style={nextButtonStyle} />}
                                            transitionDuration={0}
                                            firstDayOfWeek={1}
                                            orientation="verticalScrollable"
                                            numberOfMonths={6}
                                            noBorder

                                        />
                                    </div>
                                    {renderCalendarInfo()}

                                    {startDate && endDate &&
                                        <div
                                            onClick={(e) => {
                                                handleClear();
                                            }}
                                            className="tw-hover:text-blue-800 tw-font-bold tw-cursor-pointer tw-border tw-text-center tw-p-2 tw-mb-3">
                                            Clear Dates
                                        </div>
                                    }

                                    {getDurationBooking() &&
                                        <div className="tw-mt-0 tw-mb-2 tw-p-3 tw-bg-slate-100 ">
                                            {getDurationBooking()}
                                        </div>
                                    }
                                </div>

                            </div>
                        )}

                        {guestIsEditable && (<div className="tw-fixed tw-h-full tw-w-full tw-bg-white tw-z-50 tw-top-0 tw-left-0">

                            <div
                                className={`${styles.Save}`}

                            >
                                <ButtonBootstraps className='!tw-text-white' variant="primary" type="submit" onClick={() => setGuestIsEditable(false)}>
                                    Save
                                </ButtonBootstraps>
                            </div>


                            <div className="tw-p-5">
                                {[
                                    { label: bookingDictinaries?.Adults || 'Adults', count: adults, setCount: setAdults, min: 1, max: maxOccupancy - children },
                                    { label: bookingDictinaries?.Children || 'Children', count: children, setCount: setChildren, min: 0, max: maxOccupancy - adults },
                                    { label: bookingDictinaries?.Infants || 'Infants', count: infants, setCount: setInfants, min: 0, max: 20 },
                                    { label: bookingDictinaries?.Pets || 'Pets', count: pets, setCount: setPets, min: 0, max: maxPets }
                                ].map(({ label, count, setCount, min, max }, index) => (
                                    (label !== 'Pets' || maxPets > 0) && (
                                        <div key={index} className="tw-flex tw-justify-between tw-items-center tw-my-2">
                                            <h6 className="tw-w-1/4">{label}</h6>
                                            <div className="tw-w-3/4 tw-flex tw-justify-end">
                                                <button onClick={() => setCount(count > min ? parseInt(count) - 1 : min)} className="tw-flex tw-items-center tw-justify-center tw-border tw-rounded-full tw-w-[40px] tw-h-[40px]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="tw-w-4 tw-h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h12" />
                                                    </svg>
                                                </button>
                                                <div className="tw-px-3 tw-py-2 tw-mx-2 tw-w-[40px] tw-text-center">{count}</div>
                                                <button onClick={() => setCount(count < max ? parseInt(count) + 1 : max)} disabled={count >= max} className={`tw-flex tw-items-center tw-justify-center tw-border tw-rounded-full tw-w-[40px] tw-h-[40px] ${count >= max ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="tw-w-4 tw-h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v12m-6-6h12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>)}


                        {(error && startDate && endDate) && <p className="tw-text-red-500">{error}</p>}

                        <div className="info tw-border tw-border-primary tw-p-3 tw-my-3">
                            <h4><FontAwesomeIcon icon={faCircleInfo} color="#67b26f" size="sm" className="tw-right-5 tw-top-5  " /> Book With Confidence</h4>
                            <p>
                                {bookingDictinaries?.MessageBookWithConfidence || '30% deposit upon booking, 70% balance payment 8 weeks prior to arrival date. Additional charges payable at the property'} 
                                
                            </p>
                        </div>

                        {(!error && startDate && endDate) &&
                            <div>

                                {/* {bookingData && bookingData.RenterAmount > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>Renter Amount:</span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.RenterAmount}</span>
                                    </div>
                                } */}


                                {(bookingData && bookingData.ItemisedExtras.length > 0) &&
                                    <div className="test">
                                        {bookingData.ItemisedExtras.map((extra, index) => (
                                            <div key={index} className="tw-flex tw-justify-between">
                                                <span>{extra.Name}</span>
                                                <span>{bookingData.CurrencySymbol}{extra.Amount}</span>
                                            </div>
                                        ))}
                                    </div>
                                }

                                {bookingData && bookingData.Discount > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>

                                            {bookingDictinaries?.Discount || 'Discount:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.Discount}</span>
                                    </div>
                                }


                        




                                {bookingData && bookingData.HolidayExtraCost > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>
                                            {bookingDictinaries?.Extra || 'Holiday Extra Cost:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.HolidayExtraCost}</span>
                                    </div>
                                }

                                {bookingData && bookingData.ExtraTotal > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>
                                            {bookingDictinaries?.ExtraTotal || 'Extra Total:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.ExtraTotal}</span>
                                    </div>
                                }

                                {bookingData && bookingData.RentalRatesTax > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>
                                            {bookingDictinaries?.RentalRatesTax || 'Rental Rates Tax:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.RentalRatesTax}</span>
                                    </div>
                                }

                                {bookingData && bookingData.BookingFee > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>
                                            {bookingDictinaries?.BookingFee || 'Booking Fee:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.BookingFee}</span>
                                    </div>
                                }

                                {bookingData && bookingData.BreakagesDeposit > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>
                                            {bookingDictinaries?.BreakagesDeposit || 'Breakages Deposit:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.BreakagesDeposit}</span>
                                    </div>
                                }

                                {bookingData && bookingData.InsuranceTotal > 0 &&
                                    <div className="tw-flex tw-justify-between">
                                        <span>
                                            {bookingDictinaries?.InsuranceTotal || 'Insurance Total:'}:
                                        </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.InsuranceTotal}</span>
                                    </div>
                                }

                                <div className="tw-border-t tw-border-gray-200 tw-h-full tw-mt-3 tw-mb-2"></div>

                                {bookingData && bookingData.RenterTotal > 0 &&
                                    <div className="tw-flex tw-justify-center tw-font-bold tw-text-3xl">
                                        <span className='tw-mr-4'>
                                            {bookingDictinaries?.Total || 'Total'}:    
                                         </span>
                                        <span>{bookingData.CurrencySymbol}{bookingData.RenterTotal}</span>
                                    </div>
                                }

                                {bookingData && bookingData.PayableToday > 0 &&
                                    <div className="tw-flex tw-justify-center">
                                        <span className='tw-mr-4'>
                                            {bookingDictinaries?.PayableToday || 'Payable Today'}
                                         </span>
                                        <span className='tw-font-bold'>{bookingData.CurrencySymbol}{bookingData.PayableToday}</span>
                                    </div>
                                }
                            </div>
                        }

                        <div className="tw-mt-2">
                            {(!error && !hideOnlineBooking && startDate && endDate) &&
                                <a href={`?alttemplate=checkout_guest&t=acc&checkin=${startDate.format('DD/MM/YYYY')}&checkout=${endDate.format('DD/MM/YYYY')}&adults=${adults}&children=${children}&infants=${infants}&pets=${pets}&propertyId=${pageId}&propertylanguageId=0&sourceId=&propertyCurrencyId=`}
                                    className="tw-bg-black tw-text-white hover:tw-text-white hover:tw-bg-gray-900 tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-transition tw-duration-200">
                                    {bookingDictinaries?.Book || 'Reserve'}
                                </a>
                            }

                      


                            {/* <a href={`?alttemplate=Enquiry&${startDate && endDate ? `checkin=${startDate.format('DD/MM/YYYY')}&checkout=${endDate.format('DD/MM/YYYY')}&` : ''}adults=${adults}&children=${children}&infants=${infants}&pets=${pets}&propertyId=${pageId}&propertylanguageId=0&sourceId=&propertyCurrencyId=`}
                                className="tw-bg-primary tw-text-white hover:tw-text-white hover:tw-bg-green-400 tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200">
                                Enquire
                            </a> */}
                        </div>
                    </div>

                </div>
            }
        </>

    );
};

export default BookingForm;