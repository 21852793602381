import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './EnquiryForm.css';
import moment from 'moment';

// Set moment's locale to use the desired date format
moment.updateLocale('en', {
    longDateFormat: {
        L: 'DD/MM/YYYY'
    }
});

const EnquiryForm = ({ pageId, currencySymbol, selectedDates, enquireDictinaries, guests, maxOccupancy, maxPets }) => { 
 

    const [focusedInput, setFocusedInput] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState('');
    const [error, setError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [loading, setLoading] = useState(false);

    const [isRecaptchaEnabled, setIsRecaptchaEnabled] = useState(false);
    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('');
    const [showEnquiryFormMobile, setShowEnquiryFormMobile] = useState(false);
    const [showEnquiryFormEmail, setShowEnquiryFormEmail] = useState(false);
    const [isEnquiryFormEmailMandatory, setIsEnquiryFormEmailMandatory] = useState(false);
    const [numberOfCalendars, setNumberOfCalendars] = useState(2);

    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [pets, setPets] = useState(0);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (selectedDates.startDate && selectedDates.endDate) {
         
            setStartDate(moment(selectedDates.startDate));
            setEndDate(moment(selectedDates.endDate));
        }
    }, [selectedDates]);

    useEffect(() => {
        if (window.innerWidth < 1000) {
            setNumberOfCalendars(1);
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth < 1000) {
                setNumberOfCalendars(1);
            } else {
                setNumberOfCalendars(2);
            }
        });
    }, []);

    useEffect(() => {
        if (guests) {
            setAdults(guests.adults);
            setChildren(guests.children);
            setInfants(guests.infants);
            setPets(guests.pets);
        }
    }, [guests]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}apis/googlesitesettings`)
            .then(response => response.json())
            .then(data => {
                setIsRecaptchaEnabled(data.GoogleRecaptchaRequiredEnquiryForm);
                setRecaptchaSiteKey(data.GoogleRecaptchaSiteKey);

                setShowEnquiryFormMobile(data.ShowEnquiryFormMobile);
                setShowEnquiryFormEmail(data.ShowEnquiryFormEmail);
                setIsEnquiryFormEmailMandatory(data.IsEnquiryFormEmailMandatory);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const onChangeRecaptcha = (value) => {
        setCaptchaToken(value);
    }

    const emailValidation = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (!name || !question || (!email && showEnquiryFormEmail && isEnquiryFormEmailMandatory) || (!mobile && showEnquiryFormMobile)) {
            setError('Please fill in all fields');
            setLoading(false);
            return;
        }

        if (!emailValidation(email) && showEnquiryFormEmail && isEnquiryFormEmailMandatory) {
            setError('Please enter a valid email address');
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ipro/webmethod.ashx`, {
                params: {
                    methodName: 'AddEnquire',
                    FullName: name,
                    Email: email,
                    Mobile: mobile,
                    Additional: question,
                    RecaptchaToken: captchaToken,
                    PropertyId: pageId,
                    PropertyCurrencyId: currencySymbol,
                    Version: "v4",
                    StartDay: startDate ? startDate.format('YYYY-MM-DD') : '',
                    EndDay: endDate ? endDate.format('YYYY-MM-DD') : '' ,
                    NumAdults: adults,
                    NumChildren: children,
                    NumInfants: infants, 
                    NumPets: pets
                }
            });

            if (response.data.Error || response?.Error) {
                setError(response.data.Error);
                setResponse(null);
                setLoading(false);
            } else {
                setName('');
                setEmail('');
                setMobile('');
                setQuestion('');
                setResponse(response.data);
                setError(null);
            }
        } catch (error) {
            console.error("Error:", error);
            setError('An error occurred, please try again later');
            setLoading(false);
        }
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    }

    return (
        <div className="tw-p-5 ">
            {!response &&
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="Name">
                        <Form.Label>
                            {enquireDictinaries?.FullName || 'Name'}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Dates">
                        <Form.Label>
                            {enquireDictinaries?.Dates || 'Dates'}
                        </Form.Label>
                        <div className='enquire-form'>
                            <DateRangePicker
                                onDatesChange={handleDatesChange}
                                focusedInput={focusedInput}
                                onFocusChange={handleFocusChange}
                                startDate={startDate}
                                endDate={endDate}
                                customArrowIcon={' '}
                                block
                                showClearDates
                                noBorder
                                className='tw-max-w-[20px] tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-p-2 tw-mt-1 tw-mb-2 tw-text-sm tw-placeholder-gray-400 tw-text-gray-900 tw-focus:tw-outline-none tw-focus:tw-ring-1 tw-focus:tw-ring-green-500 tw-focus:tw-border-green-500 tw-transition tw-duration-200 tw-appearance-none tw-inline-block tw-text-left tw-bg-white'
                                numberOfMonths={numberOfCalendars}
                                startDatePlaceholderText={enquireDictinaries?.CheckInPlaceholder || 'Check In'}  
                                endDatePlaceholderText={enquireDictinaries?.CheckOutPlaceholder || 'Check Out'}
                            />
                        </div>
                    </Form.Group>

                    {showEnquiryFormMobile &&
                        <Form.Group className="mb-3" controlId="Mobile">
                            <Form.Label>
                                {enquireDictinaries?.Mobile || 'Mobile'}
                            </Form.Label>
                            <PhoneInput
                                country={'gb'}
                                preferredCountries={['gb', 'de', 'fr', 'it', 'es', 'pt']}
                                value={mobile}
                                onChange={setMobile}
                                containerClass='phone-input-container'
                                inputClass='!tw-w-full !tw-h-[38px] !tw-border-[#ced4da]'
                               
                            />
                        </Form.Group>
                    }

                    {showEnquiryFormEmail &&
                        <Form.Group className="mb-3" controlId="Email">
                            <Form.Label>
                                {enquireDictinaries?.Email || 'Email'}
                            </Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={enquireDictinaries?.EmailPlaceholder || 'Email'}
                            />
                        </Form.Group>
                    }

                    <div className="tw-mt-0">
                        {[
                            { label: enquireDictinaries?.Adults || 'Adults', count: adults, setCount: setAdults, min: 1, max: maxOccupancy - children },
                            { label: enquireDictinaries?.Children || 'Children', count: children, setCount: setChildren, min: 0, max: maxOccupancy - adults },
                            { label: enquireDictinaries?.Infants || 'Infants', count: infants, setCount: setInfants, min: 0, max: 20 },
                            { label: enquireDictinaries?.Pets || 'Pets', count: pets, setCount: setPets, min: 0, max: maxPets }
                        ].map(({ label, count, setCount, min, max }, index) => (
                            (label !== 'Pets' || maxPets > 0) && (
                                <div key={index} className="tw-flex tw-justify-between tw-items-center tw-my-1">
                                    <label className="tw-w-1/4 form-label">{label}</label>
                                    <div className="tw-w-3/4 tw-flex tw-justify-end">
                                        <button
                                            onClick={() => setCount(Math.max(min, parseInt(count, 10) - 1))}
                                            className="tw-flex tw-items-center tw-justify-center tw-border tw-rounded-full tw-w-[30px] tw-h-[30px]"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="tw-w-4 tw-h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h12" />
                                            </svg>
                                        </button>
                                        <div className="tw-px-3 tw-py-0 tw-mx-2 tw-w-[30px] tw-text-center">{count}</div>
                                        <button
                                            onClick={() => setCount(Math.min(max, parseInt(count, 10) + 1))}
                                            disabled={count >= max}
                                            className={`tw-flex tw-items-center tw-justify-center tw-border tw-rounded-full tw-w-[30px] tw-h-[30px] ${count >= max ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="tw-w-4 tw-h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v12m-6-6h12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>


                    <Form.Group className="mb-3" controlId="Question">
                        <Form.Label>
                            {enquireDictinaries?.Additional || 'Additional'}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    </Form.Group>

                    {isRecaptchaEnabled &&
                        <ReCAPTCHA
                            size=""
                            ref={recaptchaRef}
                            sitekey={recaptchaSiteKey}
                            badge="inline"
                            onChange={onChangeRecaptcha}
                        />
                    }

                    {error &&
                        <Alert variant={'danger'} className="tw-mb-2 tw-mt-3">
                            {error}
                        </Alert>
                    }

                    <div className="tw-mt-2">
                        <button
                            type="submit"
                            className="tw-w-full tw-bg-primary tw-text-white hover:tw-text-white hover:tw-bg-green-400 tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200"
                            onClick={handleSubmit}
                        >
                            {enquireDictinaries?.Submit || 'Submit'}
                            {loading &&
                                <Spinner animation="border" role="status" size="sm" className='tw-ml-2'>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                        </button>
                    </div>
                </Form>
            }

            {response && <p><div dangerouslySetInnerHTML={{ __html: response }} /> </p>}
        </div>
    );
};

export default EnquiryForm;
